<template>
  <div id="Manage">
    <!-- 供应商管理-->

    <!-- Dialog 对话框 -->
    <el-dialog :title="dialogFonfig.openType === 'add' ? dialogFonfig.addName : dialogFonfig.editName"
               :visible.sync="dialogFonfig.isShowDialogVisible"
               @click="clickCancelData"
               @close="clickCancelData">
      <CommonForm :form="openForm"
                  :formLabel="openFormLabel"
                  @modifychecked2='getSupplyID'
                  @handleRemove="handleRemove"
                  ref="form"></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmitData(dialogFonfig.openType, openForm)">确 定</el-button>
      </div>
    </el-dialog>
    <!-- Dialog 对话框 审核表单-->
    <el-dialog :title="dialogFonfigAudit.auditName"
               class="dialogAudit"
               :close-on-click-modal="false"
               :visible.sync="dialogFonfigAudit.isShowDialogVisible">
      <CommonForm :form="auditForm"
                  class="dialogAuditForm"
                  :formLabel="auditFormLabel"
                  inline></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="danger"
                   @click="clickSubmitAuditData(2, auditForm)">驳 回</el-button>
        <el-button type="success"
                   @click="clickSubmitAuditData(1, auditForm)">通 过</el-button>
      </div>
    </el-dialog>
    <CommonManageMain>
      <template v-slot:header>
        <div>
          <el-button type="primary"
                     size="small"
                     @click="clickAddData"
                     icon="el-icon-circle-plus-outline">添加供应商</el-button>
          <!-- <el-button type="danger" size="small" @click="clickDelData" icon="el-icon-delete">删除</el-button> -->

          <CommonForm :form="searchForm"
                      :formLabel="searchformLabel"
                      ref="select"
                      class="filtrateInput">
            <el-button type="primary"
                       @click="searchHotelProductToAudit(searchForm)">搜 索</el-button>
            <el-button>导出</el-button>
            <el-button type="text">查看已导出列表</el-button>
            <el-button type="text"
                       @click="resetFilter(searchForm)">重置筛选条件</el-button>
          </CommonForm>
        </div>
      </template>
      <!-- 表格内容框 -->
      <!-- <template v-slot:content>
        <CommonTable :tableData="tableData"
                     :tableLabel="tableLabel"
                     :tableConfig="tableConfig"
                     @changeSwitch='changeSwitch'
                     style="height:500px">
          <el-table-column label="操作"
                           width="180">
            <template slot-scope="scope">
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="审核"
                          placement="top">
                <span @click='clickAuditData(scope.$index, scope.row)'>审核</span>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="编辑"
                          placement="top">
                <span @click="clickEditData(scope.$index, scope.row)">编辑</span>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="删除"
                          placement="top">
                <span @click='clickDeleteData(scope.$index, scope.row)'>删除</span>
              </el-tooltip>
            </template>
          </el-table-column>
        </CommonTable>

      </template> -->
      <template v-slot:content>
        <CommonTabForm class="content"
                       :tabLabel="tableFormLabel"
                       :tableData="tableData"
                       @getPage="getPage"
                       @clickWarningData="clickAuditData"
                       @clickEditData="clickEditData"
                       @clickDeleteData="clickDeleteData"
                       @clickInfoData='clickInfoData'
                       @clickRemarks='clickStream'
                       @tabHandleClick="swichTable"></CommonTabForm>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import { getSuppliperList, methSuppliperData, getSuppliperListDetail, AuditSupplier, changeSupplierStatus } from 'api/organize.js'
import { getBasisGoodsList, getTouristArea } from 'api/basisDate.js'
import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'
import CommonTabForm from 'components/CommonTab_Form.vue'
export default {
  components: {
    CommonForm,
    CommonTable,
    CommonTabForm,
    CommonManageMain
  },
  data () {
    return {
      imgIndex: [],//获取删除后的图片
      tabIndex: 0,//存放tab的下标
      // 对话框 Dialog 基础数据
      dialogFonfig: {
        openType: 'add',
        addName: '添加供应商信息',
        editName: '编辑供应商信息',
        isShowDialogVisible: false
      },
      // 对话框 Dialog 审核供应商
      dialogFonfigAudit: {
        auditName: '待审核',
        isShowDialogVisible: false
      },

      //form表单 审核
      auditForm: {
        id: '',
        name: '',
        status: '',
        remarks: ''
      },
      auditFormLabel: [
        {
          model: 'id',
          label: '供应商ID',
          width: '400px',
          type: 'disabled'
        },
        {
          model: 'name',
          label: '供应商名称',
          width: '400px',
          type: 'disabled'
        },
        {
          model: 'remarks',
          label: '审核理由',
          type: 'textarea',
          width: '600px',
          required: true
        },

      ],

      // form表单 基础数据
      openForm: {
        username: '',
        password: '',
        nickname: '',
        abbreviation: '',
        telephone: '',
        province_id: '',
        area: '',
        city_id: '',
        county_id: '',
        town_id: 0,
        address: '',
        license_pic: [],
        entry_date: '',
        name: '',
        phone: '',
        remarks: '',
        type: [],
        goods_category_id: [],
        tour_area_id: [],
        operation_center_id: ''
      },
      openFormLabel: [
        {
          model: 'username',
          label: '账号',
          width: '400px',
        },
        {
          model: 'password',
          label: '密码',
          width: '400px',
        },
        {
          model: 'nickname',
          label: '全称',
          width: '400px',
        },
        {
          model: 'abbreviation',
          label: '简称',
          width: '400px',
        },
        {
          model: 'telephone',
          label: '联系方式',
          width: '400px',
        },
        {
          model: 'area',
          area: {
            area1: 'province_id',
            area2: 'city_id',
            area3: 'county_id',
            area4: 'town_id'
          },
          label: '地址',
          type: 'cascaderArea',
          width: '100%',
          // required: true
        },
        {
          model: 'address',
          label: '详细地址',
          width: '400px',
        },
        {
          model: 'license_pic',
          label: '营业执照图片',
          type: 'uploadList',
          maxSize: 2048,
          imgHeight: '1280',
          imgWidth: '808',
        },
        {
          model: 'entry_date',
          type: 'datetime',
          label: '入驻时间',
          width: '400px',
        },
        {
          model: 'name',
          label: '负责人',
          width: '400px',
        },
        {
          model: 'phone',
          label: '负责人手机',
          width: '400px',
        },
        {
          model: 'remarks',
          label: '备注',
          width: '400px',
        },
        {
          model: 'type',
          type: 'supplyType',
          options: [
            { id: 1, sType: '旅游' },
            { id: 2, sType: '酒店' },
            // { id: 2, sType: '民宿' },
            { id: 3, sType: '商品' },
            { id: 4, sType: '门票' },
          ]
        },
        {
          model: 'goods_category_id',
          label: '商品分类ID',
          type: 'treeSelect',
          options: [],
          defaultProps: {
            label: "label",
            children: "children"
          },
          collapseTags: false,
          nodeKey: 'value',
        },
        {
          model: 'tour_area_id',
          label: '主要旅游区域',
          type: 'treeSelect',
          options: [],
          defaultProps: {
            label: "label",
            children: "children"
          },
          collapseTags: false,
          nodeKey: 'value',
        },
        {
          model: 'operation_center_id',
          label: '关联运营中心ID',
          width: '400px',
        },
      ],

      // form表单 搜索数据
      searchForm: {
        search: '',
        phone: '',
        operation_center_id: '',
      },
      searchformLabel: [
        {
          model: 'search',
          label: '供应商名称',
          width: '300px'
        },
        {
          model: 'phone',
          label: '联系方式',
          width: '300px'
        },
        // {
        //   model: 'status',
        //   label: '状态',
        //   type: 'select',
        //   width: '250px',
        //   options: [
        //     // value:'值',label:'标签'
        //     { value: 0, label: '申请' },
        //     { value: 1, label: '正常' },
        //     { value: 2, label: '驳回' },
        //     { value: 3, label: '禁用' },
        //   ],
        // },
        {
          model: 'operation_center_id',
          label: '所属运营中心ID',
          width: '300px'
        },
      ],
      // table 表格 基础数据
      tableData: [],
      tableFormLabel: [
        {
          name: 'first',
          label: "待审核",
          type: 'table',
          tableLabel: [
            {
              prop: 'ID',
              label: '供应商ID',
            },
            {
              prop: 'nickname',
              label: '全称'
            },
            {
              prop: 'abbreviation',
              label: '简称'
            },
            {
              prop: 'telephone',
              label: '联系方式'
            },
            {
              prop: 'name',
              label: '负责人'
            },
            {
              prop: 'entry_date',
              label: '创建时间'
            },
            {
              prop: 'type',
              label: '供应类型',
              width: '200px'
            },
            {
              prop: 'operation',
              label: '操作',
              width: '200px',
              type: 'operation',
              children: [
                {
                  content: '编辑',
                  type: 'primary'
                },
                {
                  content: '审核',
                  type: 'warning'
                },
                {
                  content: '删除',
                  type: 'danger'
                }
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            NumArticle: 10,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },
        {
          name: 'second',
          label: "运营列表",
          type: 'table',
          tableLabel: [
            {
              prop: 'ID',
              label: '供应商ID',
            },
            {
              prop: 'nickname',
              label: '全称'
            },
            {
              prop: 'abbreviation',
              label: '简称'
            },
            {
              prop: 'telephone',
              label: '联系方式'
            },
            {
              prop: 'name',
              label: '负责人'
            },
            {
              prop: 'entry_date',
              label: '创建时间'
            },
            {
              prop: 'type',
              label: '供应类型',
              width: '200px'
            },
            {
              prop: 'operation',
              label: '操作',
              width: '200px',
              type: 'operation',
              children: [
                {
                  content: '禁用',
                  type: 'info'
                },
                {
                  content: '流水',
                  type: 'remarks'
                },
                {
                  content: '编辑',
                  type: 'primary'
                },
                {
                  content: '删除',
                  type: 'danger'
                }
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            NumArticle: 10,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },
        {
          name: 'third',
          label: "已驳回",
          type: 'table',
          tableLabel: [
            {
              prop: 'ID',
              label: '供应商ID',
            },
            {
              prop: 'nickname',
              label: '全称'
            },
            {
              prop: 'abbreviation',
              label: '简称'
            },
            {
              prop: 'telephone',
              label: '联系方式'
            },
            {
              prop: 'name',
              label: '负责人'
            },
            {
              prop: 'entry_date',
              label: '创建时间'
            },
            {
              prop: 'type',
              label: '供应类型',
              width: '200px'
            },
            {
              prop: 'operation',
              label: '操作',
              width: '200px',
              type: 'operation',
              children: [
                {
                  content: '编辑',
                  type: 'primary'
                },
                {
                  content: '审核',
                  type: 'warning'
                },
                {
                  content: '删除',
                  type: 'danger'
                }
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            NumArticle: 10,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },
        {
          name: 'fourth',
          label: "已禁用",
          type: 'table',
          tableLabel: [
            {
              prop: 'ID',
              label: '供应商ID',
            },
            {
              prop: 'nickname',
              label: '全称'
            },
            {
              prop: 'abbreviation',
              label: '简称'
            },
            {
              prop: 'telephone',
              label: '联系方式'
            },
            {
              prop: 'name',
              label: '负责人'
            },
            {
              prop: 'entry_date',
              label: '创建时间'
            },
            {
              prop: 'type',
              label: '供应类型',
              width: '200px'
            },
            {
              prop: 'operation',
              label: '操作',
              width: '200px',
              type: 'operation',
              children: [
                {
                  content: '启用',
                  type: 'info'
                },
                {
                  content: '流水',
                  type: 'remarks'
                },
                {
                  content: '编辑',
                  type: 'primary'
                },
                {
                  content: '删除',
                  type: 'danger'
                }
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            NumArticle: 10,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },
      ],

      // 供应商-全部-数据
      supplierAlldataObj: {},

      // id最大值
      MaxId: '',
      activeTableName: 'first',
      optionsGoods: [],//商品分类数据
      optionsTouristArea: [],//旅游区域数据
    }
  },
  beforeRouteEnter (to, from, next) {
    if (from.name == 'supplierStream') {
      to.meta.isBack = true;
      //判断是从哪个路由过来的，
      //如果是page2过来的，表明当前页面不需要刷新获取新数据，直接用之前缓存的数据即可
    }
    //继续执行
    next();
  },
  activated () {
    if (!this.$route.meta.isBack || this.isFirstEnter) {
      // 如果isBack是false，表明需要获取新数据，否则就不再请求，直接使用缓存的数据
      // 如果isFirstEnter是true，表明是第一次进入此页面或用户刷新了页面，需获取新数据
      // 执行自己写的页面的初始化
      this.updataTableData();
    }
    // 恢复成默认的false，避免isBack一直是true，导致下次无法获取数据
    this.$route.meta.isBack = false
    // 恢复成默认的false，避免isBack一直是true，导致每次都获取新数据
    this.isFirstEnter = false;
  },
  created () {
    this.isFirstEnter = true;
    // 只有第一次进入或者刷新页面后才会执行此钩子函数，使用keep-alive后（2+次）进入不会再执行此钩子函数
  },
  mounted () {
    this.updataTableData();
  },
  beforeUpdate () {
  },
  watch: {
    supplierAlldataObj: function () {
      this.tableData = this.dataFormatDeal(
        this.supplierAlldataObj.list,
        {
          nickname: 'nickname',
          id: "ID",
          abbreviation: 'abbreviation',
          telephone: 'telephone',
          entry_date: 'entry_date',
          status: 'status',
          type: 'type',
        },
      )
    },
    // 商品分类选择
    optionsGoods: function () {
      this.openFormLabel[13].options = this.dataFormatDeal(this.optionsGoods, {
        id: 'value',
        category_name: 'label',
        child: 'children',
      }, 1)
    },
    // 旅游区域 分类选择
    optionsTouristArea: function () {
      this.openFormLabel[14].options = this.dataFormatDeal(this.optionsTouristArea, {
        id: 'value',
        name: 'label',
        child: 'children',
      }, 1)
    },
  },

  methods: {
    //商品分类数据
    getBasisGoodsList () {
      return new Promise(resolve => {
        getBasisGoodsList().then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.optionsGoods = DATA.data.list
            resolve(DATA.data)
          } else {
            this.$message({
              type: 'error',
              message: '已有商品获取失败'
            })
          }
        })
      })
    },
    //旅游区域分类数据
    getTouristArea () {
      return new Promise(resolve => {
        getTouristArea().then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.optionsTouristArea = DATA.data
            resolve(DATA.data)
          } else {
            this.$message({
              type: 'error',
              message: '已有旅游区域获取失败'
            })
          }
        })
      })
    },
    //处理时间戳
    formatDate (time) {
      var year = time.getFullYear();
      var month = time.getMonth() + 1;
      var date = time.getDate()
      return year + '-' + month + '-' + date
    },
    //获取复选框的ID
    getSupplyID (data) {
      console.log(data)
      if (data) {
        let arr = []
        data.forEach(item => {
          arr.push(item.supplyItemId)
        })
        for (var i = 0; i < arr.length; i++) {

          arr[i] = JSON.stringify(arr[i]);
        }
        console.log(arr)
        this.openForm.type = arr
        console.log(this.openForm.type)
        console.log(event.target.checked)

      }

    },
    getPage () {
      if (this.searchData) {
        this.searchData.page = this.tableConfig.page
        this.getSuppliperList(this.searchData)
      } else {
        this.getSuppliperList()
      }
    },
    // 获取--供应商列表数据
    getSuppliperList (data) {
      if (data) {
        getSuppliperList(data).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            DATA.data.list.forEach(item => {
              //处理创建时间
              item.entry_date = new Date(item.entry_date * 1000)
              item.entry_date = this.formatDate(item.entry_date)
              //处理供应类型数据
              for (let i = 0; i < item.type.length; i++) {
                if (item.type[i] == '0') {
                  item.type[i] = '旅游'
                } else if (item.type[i] == '1') {
                  item.type[i] = '酒店'
                } else if (item.type[i] == '2') {
                  item.type[i] = '民宿'
                } else if (item.type[i] == '3') {
                  item.type[i] = '商品'
                } else if (item.type[i] == '4') {
                  item.type[i] = '门票'
                }
              }
              item.type = item.type.join()
            })
            this.supplierAlldataObj = DATA.data
            this.tableFormLabel[this.tabIndex].tableConfig.total = DATA.data.total
          }
        })
      } else {
        getSuppliperList({
          status: this.tabIndex,
          page: this.tableFormLabel[this.tabIndex].tableConfig.page,
          page_rows: 10
        }).then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            DATA.data.list.forEach(item => {
              //处理创建时间
              item.entry_date = new Date(item.entry_date * 1000)
              item.entry_date = this.formatDate(item.entry_date)
              //处理供应类型数据
              for (let i = 0; i < item.type.length; i++) {
                if (item.type[i] == '0') {
                  item.type[i] = '旅游'
                } else if (item.type[i] == '1') {
                  item.type[i] = '酒店'
                } else if (item.type[i] == '2') {
                  item.type[i] = '民宿'
                } else if (item.type[i] == '3') {
                  item.type[i] = '商品'
                } else if (item.type[i] == '4') {
                  item.type[i] = '门票'
                }
              }
              item.type = item.type.join()
            })
            this.supplierAlldataObj = DATA.data

            this.tableFormLabel[this.tabIndex].tableConfig.total = DATA.data.total
            this.tableFormLabel[this.tabIndex].tableConfig.loading = false
          }

        })
      }



    },

    //添加数据   --添加-编辑 供应商管理
    methSuppliperData (met, data) {
      return new Promise(resolve => {
        methSuppliperData(met, data).then(res => {
          console.log(res)
          const DATA = res.data
          resolve(DATA)
          console.log(DATA)
        })
      })

    },
    //审核供应商
    AuditSupplier (data) {
      console.log(data)
      return new Promise(resolve => {
        AuditSupplier(data).then((res) => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            resolve(res)
          } else {
            this.$message({
              message: DATA.message,
              type: 'warning'
            })
          }
        })
      })
    },
    //更新供应商状态
    changeSupplierStatus (data) {
      console.log(data)
      return new Promise(resolve => {
        changeSupplierStatus(data).then((res) => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            resolve(res)
          } else {
            this.$message({
              message: DATA.message,
              type: 'warning'
            })
          }
        })
      })
    },
    // 数据格式处理
    dataFormatDeal (arr, keyMap, copyInKeyMap) {
      // global 全局方法处理

      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },

    // 获取最大的 id值
    MaxID (arr, keyMap) {
      let maxid = 0
      for (const val of arr) {
        maxid = maxid > val[keyMap] ? maxid : val[keyMap]
      }
      return maxid
    },

    // 添加数据
    clickAddData () {
      let BasisGoodsList = this.getBasisGoodsList()
      let touristArea = this.getTouristArea()
      Promise.all([BasisGoodsList, touristArea]).then(res => {
        this.dialogFonfig.isShowDialogVisible = true
      })
      console.log(this.optionsGoods)
      this.MaxId = this.MaxID(this.tableData, 'ID')
      this.openForm.id = this.MaxId + 1
      this.dialogFonfig.openType = 'add'
      this.getSupplyID()
    },

    // 确定提交数据操作
    async clickSubmitData (type, data) {
      let res
      // data.entry_date = data.entry_date.toString()
      // console.log(data.entry_date)
      // for (var i = 0; i < 3; i++) {
      //   data.entry_date = data.entry_date.substr(0, data.entry_date.length - 1)
      // }
      // data.entry_date = Number(data.entry_date)
      if (type == 'add') {
        const DATA = {
          username: data.username,
          password: data.password,
          nickname: data.nickname,
          abbreviation: data.abbreviation,
          telephone: data.telephone,
          province_id: data.province_id,
          city_id: data.city_id,
          county_id: data.county_id,
          town_id: 0,
          address: data.address,
          license_pic: data.license_pic,
          entry_date: data.entry_date,
          name: data.name,
          phone: data.phone,
          remarks: data.remarks,
          type: data.type,
          goods_category_id: data.goods_category_id,
          tour_area_id: data.tour_area_id,
          operation_center_id: data.operation_center_id,
        }
        console.log(this.openForm)
        DATA.entry_date = new Date(DATA.entry_date).getTime() / 1000
        res = await this.methSuppliperData('POST', DATA)
      }
      else if (type == 'edit') {
        this.imgIndex.forEach(item => {
          data.license_pic.splice(this.imgIndex, 1)
        })
        const DATA = {
          id: data.id,
          username: data.username,
          password: data.password,
          nickname: data.nickname,
          abbreviation: data.abbreviation,
          telephone: data.telephone,
          province_id: data.province_id,
          city_id: data.city_id,
          county_id: data.county_id,
          town_id: 0,
          address: data.address,
          license_pic: data.license_pic,
          entry_date: data.entry_date,
          name: data.name,
          phone: data.phone,
          remarks: data.remarks,
          type: data.type,
          goods_category_id: data.goods_category_id,
          tour_area_id: data.tour_area_id,
          operation_center_id: data.operation_center_id,
        }
        DATA.entry_date = new Date(DATA.entry_date).getTime() / 1000
        res = await this.methSuppliperData('PUT', DATA)
      }
      console.log(res)
      if (res.code == 200) {
        console.log(res)
        this.$message({
          message: res.message,
          type: 'success'
        })
        this.updataTableData()
        this.dialogFonfig.isShowDialogVisible = false
        this.openForm = this.$options.data().openForm;
      } else {
        this.$message({
          message: res.message,
          type: 'error'
        })
      }

    },
    // 取消提交数据操作
    clickCancelData () {
      this.dialogFonfig.isShowDialogVisible = false
      // 用于当前 Vue 实例的初始化选项
      this.openForm = this.$options.data().openForm
      //审核对话框
      this.dialogFonfigAudit.isShowDialogVisible = false
      this.auditName = this.$options.data().auditName
    },

    // 编辑操作
    async clickEditData (row) {
      await this.getBasisGoodsList()//加载商品分类
      await this.getTouristArea()//加载旅游区域
      const data = {
        id: row.ID
      }
      await getSuppliperListDetail(data).then((res) => {
        const DATA = res.data
        if (DATA.code == 200) {
          this.$message({
            message: DATA.message,
            type: 'success'
          })
        }
        this.imgIndex.forEach(item => {
          DATA.data.license_pic.splice(this.imgIndex, 1)
        })
        this.imgIndex = []
        this.openForm = {
          abbreviation: DATA.data.abbreviation,
          address: DATA.data.address,
          city_id: DATA.data.city_id,
          county_id: DATA.data.county_id,
          entry_date: DATA.data.entry_date * 1000,
          goods_category_id: DATA.data.goods_category_id,
          id: DATA.data.id,
          license_pic: DATA.data.license_pic,
          name: DATA.data.name,
          nickname: DATA.data.nickname,
          operation_center_id: DATA.data.operation_center_id,
          phone: DATA.data.phone,
          province_id: DATA.data.province_id,
          remarks: DATA.data.remarks,
          telephone: DATA.data.telephone,
          tour_area_id: DATA.data.tour_area_id,
          town_id: 0,
          type: DATA.data.type,
          username: DATA.data.username,
          area: [DATA.data.province_id,
          DATA.data.city_id,
          DATA.data.county_id,
          ],
        }
        console.log(DATA.data.type)
        console.log(this.openForm)
      })


      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'edit'
    },
    // 删除操作
    clickDeleteData (row) {
      this.$confirm('确定移除这条记录吗？', '提示', { type: "error" }).then(() => {
        const DATA = {
          id: row.id
        }
        this.methSuppliperData('DELETE', DATA)
        this.updataTableData()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },
    //审核操作
    clickAuditData (row) {
      // ----------------数据格式-----------------
      // id	是	int	ID
      // status	是	int	状态 3、通过 2、驳回
      // remarks	是	string	审核理由
      console.log(row)
      this.auditForm = {
        id: row.ID,
        name: row.nickname,
        status: row.status,
      }
      this.dialogFonfigAudit.isShowDialogVisible = true;
    },
    //审核结果提交
    async clickSubmitAuditData (statue, data) {
      // ----------------数据格式-----------------
      //id	是	int	ID
      // status	是	int	状态 3、通过 2、驳回
      const DATA = {
        id: data.id,
        status: statue,
        remarks: data.remarks
      }
      await this.AuditSupplier(DATA).then(async (res) => {
        if (res.code == 200) {
          await this.updataTableData();
        }
      });//调用审核接口  
      this.updataTableData()
      this.dialogFonfigAudit.isShowDialogVisible = false
    },
    //刷新表格
    updataTableData () {
      this.getSuppliperList()
    },
    //禁用/启用按键
    clickInfoData (row) {
      console.log(row.status)
      if (row.status == 1) {
        const DATA = {
          id: row.ID,
          status: 3
        }
        this.changeSupplierStatus(DATA)
      } else if (row.status == 3) {
        const DATA = {
          id: row.ID,
          status: 1
        }
        this.changeSupplierStatus(DATA)
      }
      this.updataTableData()
    },
    //标签页切换
    swichTable (tab) {
      const name = tab.name
      this.activeTableName = name


      this.tabIndex = tab.index

      console.log(this.tabIndex)
      this.updataTableData()
    },
    //流水按钮操作
    clickStream (row) {
      console.log(row)
      this.$router.push({ name: 'supplierStream', query: { supplier_id: row.ID } })
    },
    // 查询操作
    searchHotelProductToAudit (searchForm) {
      const data = {
        search: searchForm.search,
        phone: searchForm.phone,
        operation_center_id: searchForm.operation_center_id,
      }

      this.searchData = data
      data.page = this.tableFormLabel[this.tabIndex].tableConfig.page
      data.page_rows = this.tableFormLabel[this.tabIndex].tableConfig.NumArticle
      console.log(data)
      data.status = this.tabIndex
      this.getSuppliperList(data)
      console.log(searchForm)
    },
    //重置筛选条件操作
    resetFilter (searchForm) {
      searchForm.search = '',
        searchForm.phone = '',
        searchForm.operation_center_id = ''
      this.updataTableData()
    },
    //获取从CommonUploadList传来的图片删除数据
    handleRemove (data) {
      this.imgIndex.push(data)
    },

  }
}
</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
  .filtrateInput {
    background: #f8f7f7;
    padding: 12px 8px;
    padding-right: 45%;
    margin: 8px 0px;
  }
  span {
    font-size: 14 px;
    margin-left: 8px;
    color: #0486fe;
    cursor: pointer;
  }
  /deep/ .el-dialog {
    width: 35%;
  }
}
</style>
